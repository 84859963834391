import React, { useRef } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import "./Planning.css"
import { Link } from "gatsby"

const VARIANTS = ["Détente", "Visite", "Pour sortir"]

const Picture = ({ variant, onClick }) => {
  if (variant === "Détente") {
    return (
      <StaticImage
        src={`../images/types/détente.jpg`}
        width={250}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        onClick={onClick}
        className="typePicture"
      />
    )
  }

  if (variant === "Visite") {
    return (
      <StaticImage
        src={`../images/types/en-famille.jpg`}
        width={250}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        onClick={onClick}
        className="typePicture"
      />
    )
  }

  if (variant === "Pour sortir") {
    return (
      <StaticImage
        src={`../images/types/pour-sortir.jpg`}
        width={250}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        onClick={onClick}
        className="typePicture"
      />
    )
  }
  return null
}

const TripCard = ({ v, pageContext }) => {
  return (
    <Link className="tripCard" to={`/planning2/${pageContext.name.toLowerCase()}/${(""+v).toLowerCase().replace(" ", "-")}`}>
      <h3>{v}</h3>
      <Picture variant={v}></Picture>
      <span className="button">Continuer</span>
    </Link>
  )
}

const PlanningTemplate = ({ pageContext }) => {
  return (
    <Layout noPro>
      <Seo title={`TripPlanR: Nos plannings à ${pageContext.name}`} />

      <div className="hero heroPlanning">
        <h1>Quel type de voyage vous tente à <span className="textOrange">{pageContext.name}</span>&nbsp;?</h1>

        <div className="tripCards">
          {VARIANTS.map(v => (
            <TripCard v={v} pageContext={pageContext} />
          ))}
        </div>
        <p className="smallForm">
          Envie d'un voyage unique sur mesure ?&nbsp; 
          <a
            href="https://calendly.com/tripplanr-b2c/45m-call-b2c"
            target="_blank"
            rel="noreferrer"
          >
            Ca se passe ici !
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default PlanningTemplate
